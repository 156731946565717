<template>
  <div v-if="show" class="coming-soon-modal ui page dimmer transition visible active">
    <div class="content">
      <t-icon :icon="[ 'fal', 'clock' ]" />
      <h1 class="header">Funcionalidade ainda não disponível!</h1>
      <h2 class="sub header">Aguarde as próximas versões! Em breve essa funcionalidade estará disponível para você!</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TComingSoon',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui.dimmer {
  &.coming-soon-modal {
    background-color: rgba(#e2e2e3, 0.3);
    text-align: center;
    pointer-events: none;
    display: flex !important;
    z-index: 990;

    svg {
      color: #3cb3f5;
      width: 140px;
      height: 140px;
    }

    h1.header {
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      color: $black-0;
    }

    h2.sub.header {
      font-size: 16px;
      line-height: 20px;
      color: $black-0;
    }
  }
}
</style>
